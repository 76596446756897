import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Healthcare</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Healthcare
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4">Healthcare</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/healthcare.webp"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      At {companyname}, we take pride in providing specialized
                      and dedicated solutions for pharmaceutical goods with both
                      passive and active packaging options. Our expertise
                      extends to handling pharmaceutical products classified as
                      Dangerous Goods (DG) and valuable items that fall under
                      the respective product categories.
                      <br />
                      <br />
                      Our team comprises skilled and certified personnel spread
                      across the entire {companyname} network, ensuring the
                      highest standards of safety and compliance in handling
                      healthcare products. We recognize that some medical
                      products may have unique characteristics that could be
                      relevant to various {companyname} products and services,
                      and we tailor our approach accordingly to meet specific
                      requirements.
                      <br />
                      <br />
                      With a deep understanding of the complexities involved in
                      transporting medical equipment, we offer seamless
                      solutions for items with or without lithium batteries
                      inside. Additionally, our experienced team is
                      well-equipped to handle medical disinfectants or freeze
                      sprays, adhering to strict safety protocols and regulatory
                      guidelines. For temperature-sensitive pharmaceuticals,
                      including those containing hazardous materials, our
                      specialized services ensure that these products are
                      transported with the utmost care and precision. We
                      maintain the required temperature controls to safeguard
                      the integrity of such items throughout the logistics
                      process.
                      <br />
                      <br />
                      First aid kits with medical sprays are treated with
                      special attention, considering their unique
                      characteristics. Our dedicated handling procedures ensure
                      that these items are transported safely and securely,
                      meeting all necessary regulations and guidelines.
                      <br />
                      <br />
                      At {companyname}, our commitment to excellence and safety
                      drives us to go above and beyond in providing specialized
                      solutions for pharmaceutical and medical products. With
                      our expertise, certified personnel, and extensive network,
                      you can trust us to handle your healthcare goods with the
                      utmost care and professionalism, ensuring their safe and
                      timely delivery to their intended destinations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
