import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Oil & Gas</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Oil & Gas
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4">Oil & Gas</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/oil.jpg"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      As oil and gas industrial projects continue to expand
                      across various regions worldwide, the demand for expert
                      and specialized air logistics solutions for transporting
                      project shipments on site is on the rise. At {companyname}
                      , our team of dedicated professionals excels in providing
                      fast, seamless, and top-quality solutions to meet these
                      unique challenges.
                      <br />
                      <br />
                      Among our services, we specialize in the carriage of large
                      and heavy drilling offshore equipment, including pipes,
                      generators, pumps, and other essential components. Our
                      expertise in handling extra-large and heavy cargo ensures
                      the safe and efficient transportation of these critical
                      assets to their designated locations.
                      <br />
                      <br />
                      When unforeseen situations arise, such as rig down
                      incidents, we offer dedicated charter services or
                      efficient re-routing options to minimize disruptions and
                      keep your projects on track. Our team is well-versed in
                      adapting to changing circumstances, ensuring that your
                      shipments reach their destinations promptly and reliably.
                      <br />
                      <br />
                      Furthermore, we boast main-deck capacity throughout our
                      extensive international network, allowing us to handle
                      large and challenging shipments with ease. With our global
                      reach and robust infrastructure, we can swiftly and
                      efficiently transport your project cargo to any
                      destination in the world.
                      <br />
                      <br />
                      At {companyname}, we take pride in being your trusted
                      partner in air logistics solutions for the oil and gas
                      industry. Our commitment to fast, seamless, and
                      high-quality services makes us the ideal choice for
                      meeting your unique project transportation needs. When you
                      choose {companyname}, you gain access to a team of
                      seasoned professionals dedicated to ensuring the success
                      of your oil and gas industrial projects through efficient
                      and reliable air logistics solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
