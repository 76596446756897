import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Ocean Freight</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Ocean Freight
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img
                      src="assets/images/ocean.webp"
                      alt="{companyname}"
                    />
                  </div>
                  <div className="training__details-content">
                    <h2>Ocean Freight</h2>
                    <p>
                      At {companyname}, we take pride in providing hassle-free
                      ocean freight forwarding solutions that streamline the
                      shipping process for our clients. With our fast online
                      quotes and user-friendly tools, you can easily access
                      shipping information and make informed decisions. Our
                      dedicated team ensures that your cross-border freight is
                      delivered on time, eliminating unnecessary delays and
                      complexities.
                      <br />
                      <br />
                      Through our extensive global network, we extend our
                      services to all major seaports across the world.
                      Leveraging this network and our strong carrier alliances,
                      we offer secure and cost-effective ocean freight solutions
                      for export, import, and cross-trade shipments. Whether you
                      are sending goods abroad or receiving them from
                      international markets, our expertise in sea freight
                      management ensures a smooth and efficient process.
                      <br />
                      <br />
                      In addition to standard ocean freight services, we offer a
                      unique "sea-air" hybrid operation that provides
                      significant freight savings. This option combines the
                      advantages of both sea and air transport, allowing for
                      faster delivery times and cost-effectiveness.
                      <br />
                      <br />
                      As specialists in logistics solutions, we prioritize
                      time-sensitive cargo management and excel in coordinating
                      ocean freight shipments worldwide. Our team is dedicated
                      to handling your cargo with care and precision, ensuring
                      that it reaches its destination safely and on schedule.
                      <br />
                      <br />
                      With {companyname} as your partner, you can count on
                      seamless ocean freight forwarding services, efficient
                      management of your shipments, and the confidence that your
                      goods will be delivered with reliability and
                      professionalism. Trust us to navigate the complexities of
                      international shipping, providing you with a stress-free
                      and reliable solution for all your ocean freight needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
