import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Vision And Values</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Vision And Values
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
     {/* ==== / event section end ==== */}
     <section
        className="section facility wow fadeInUp"
        data-wow-duration="0.4s"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h5 className="section__header-sub-title">Vision & Values</h5>
                <h2 className="section__header-title">
                  Our Vision and Values Towards Growth
                </h2>
                
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-10 col-lg-6">
              <div className="facility__card">
                <div className="facility__card-icon">
                <i class="fa-solid fa-eye"></i>
                </div>
                <div className="facility__card-content">
                  <h5>
                    <Link to="facility-details.html">Vision</Link>
                  </h5>
                  <p className="secondary-text">
                  The {companyname} aims to be a customer-oriented, multi-technology, multi-specialist transport system in the Indian and International markets. We are committed to excellence in every aspect of our activities and follow value-based policies to meet the aspirations of society, customers, vendors, employees, shareholders, and the transport industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-lg-6">
              <div className="facility__card">
                <div className="facility__card-icon">
                <i class="fa-solid fa-bullseye"></i>

                </div>
                <div className="facility__card-content">
                  <h5>
                    <Link to="facility-details.html">Values</Link>
                  </h5>
                  <p className="secondary-text">
                  At our core, we firmly believe that a brand is more than just a name; it is a living entity with a distinct personality. As such, we ensure that our brand's behavior aligns with our values and principles. Our actions and decisions are governed by a set of CORE values that serve as the guiding force in our business endeavors, helping us pave the way for a better tomorrow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / facility section end ==== */}
        {/* ==== team section start ==== */}
        <section className="section team wow fadeInUp" data-wow-duration="0.4s">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h5 className="section__header-sub-title">Our Team</h5>
                <h2 className="section__header-title">Meet Our Experts</h2>
                <p>
                  Golftio Sports Club is a golf club with a history that goes
                  back to XX century. From a cricket club to soccer tournaments,
                </p>
              </div>
            </div>
          </div>
          <div className="team__slider--secondary">
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/healthcare.webp"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Healthcare">
                  {" "}
                  <h5>Healthcare</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/oil.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Oil">
                  {" "}
                  <h5>Oil & Gas</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/heavy.jpeg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Heavy">
                  {" "}
                  <h5>Heavy Machinary</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/fashion.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Fashion">
                  {" "}
                  <h5>Fashion & Apparel</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/high.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/High">
                  {" "}
                  <h5>High Tech</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/E-Commerce.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Ecommerce">
                  {" "}
                  <h5>E-Commerce</h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="slider-navigation">
                <button className="next-team--secondary cmn-button cmn-button--secondary">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="prev-team--secondary cmn-button cmn-button--secondary">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / team section end ==== */}
      <Footer />
    </>
  );
}
