import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Fashion & Apparel</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Fashion & Apparel
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4">Fashion & Apparel</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/oil.jpg"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      In the ever-evolving fashion industry, speed and timely
                      delivery have become crucial to meet the increasing
                      demands of customers. At {companyname}, we recognize the
                      importance of seamless transportation in this dynamic
                      sector and follow our customers at every step of the
                      manufacturing process, ensuring the swift and reliable
                      delivery of high-quality products from manufacturing
                      premises to shopping shelves.
                      <br />
                      <br />
                      To uphold the integrity of your cargo, we offer
                      multi-level security control and have developed stringent
                      procedures that enhance the safety of your shipments. With
                      our focus on safety and security, you can rest assured
                      that your fashion goods are in safe hands throughout the
                      transportation process.
                      <br />
                      <br />
                      Our extensive international network links major
                      manufacturing points with the largest consumption areas,
                      enabling efficient and direct connections between the
                      fashion industry's key hubs. We are committed to providing
                      you with reliable transportation services that bridge the
                      gap between production and consumption, helping you reach
                      your target markets quickly and efficiently.
                      <br />
                      <br />
                      To provide transparency and real-time updates, we offer an
                      online track and trace tool that allows you to monitor
                      your cargo's status 24/7. This feature empowers you with
                      visibility and control over your shipments, ensuring that
                      you are well-informed at every stage of the transportation
                      journey.
                      <br />
                      <br />
                      At {companyname}, we understand the fast-paced nature of
                      the fashion industry, and our dedicated team is committed
                      to meeting your time-sensitive logistics needs. With our
                      seamless transportation solutions, multi-level security
                      measures, and international network, we are your trusted
                      partner in delivering high-quality fashion products
                      swiftly and efficiently to global markets. Let us be the
                      backbone of your fashion supply chain, ensuring that your
                      products reach their destination on time, every time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
