import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
     
      
          {/* ==== header start ==== */}
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="nav">
                <div className="nav__content">
                  <div className="nav__logo">
                    <Link to="index-2.html">
                      <img src="logo.png" alt="Logo" style={{width:'100px'}} />
                    </Link>
                  </div>
                  <div className="nav__menu">
                    <div className="nav__menu-logo d-flex d-xl-none">
                      <Link to="index-2.html" className="text-center hide-nav">
                        <img src="logo.png" alt="Logo" style={{width:'100px'}}  />
                      </Link>
                      <Link to="javascript:void(0)" className="nav__menu-close">
                        <i className="fa-solid fa-xmark" />
                      </Link>
                    </div>
                    <ul className="nav__menu-items">
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <Link to="/Home" className="nav__menu-link ">
                          Home
                        </Link>
                        
                      </li>
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <Link to="javascript:void(0)" className="nav__menu-link nav__menu-link--dropdown">
                          Company Profile
                        </Link>
                        <ul className="nav__dropdown">
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/About">About Company</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Vision">Vision & Values</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <Link to="javascript:void(0)" className="nav__menu-link nav__menu-link--dropdown">
                          What We Do
                        </Link>
                        <ul className="nav__dropdown">
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Ocean">Ocean Freight</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Air">Air Freight</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Road">Road Freight</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Customs">Customs Clearance</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Warehousing">Warehousing</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Dangerous">Dangerous Cargo</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <Link to="javascript:void(0)" className="nav__menu-link nav__menu-link--dropdown">
                          Industry Solutions
                        </Link>
                        <ul className="nav__dropdown">
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Healthcare">Healthcare</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Oil">Oil & Gas</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Heavy">Heavy Machinary</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Fashion">Fashion & Apparel</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/High">High Tech</Link>
                          </li>
                          <li>
                            <Link className="nav__dropdown-item hide-nav" to="/Ecommerce">E-Commerce</Link>
                          </li>
                          
                        </ul>
                      </li>
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <Link to="/Contact" className="nav__menu-link ">
                          Contact
                        </Link>
                        
                      </li>
                      
                      
                      <li className="nav__menu-item d-block d-md-none">
                        
                        <Link to="/Getquote" className="cmn-button">Request Quote</Link>
                      </li>
                    </ul>
                    
                  </div>
                  <div className="nav__uncollapsed">
                    
                    <div className="nav__uncollapsed-item d-none d-md-flex">
                      
                      <Link to="/Getquote" className="cmn-button">Request Quote</Link>
                    </div>
                    <button className="nav__bar d-block d-xl-none">
                      <span className="icon-bar top-bar" />
                      <span className="icon-bar middle-bar" />
                      <span className="icon-bar bottom-bar" />
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="backdrop" />
      </header>
      {/* ==== / header end ==== */}
      

      <Outlet />
    </>
  );
};

export default Header;
