import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Road Freight</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Road Freight
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img src="assets/images/road.jpg" alt="{companyname}" />
                  </div>
                  <div className="training__details-content">
                    <h2>Road Freight</h2>
                    <p>
                      {companyname} stands out as a reliable choice for trucking
                      and multimodal transportation due to our extensive network
                      of associates, truckers, vendors, and operators. This vast
                      network allows us to offer tailor-made transport solutions
                      using road transport and multimodal options, ensuring that
                      your cargo reaches its destination efficiently and safely.
                      <br />
                      <br />
                      When you choose {companyname} for trucking and multimodal
                      transportation, you benefit from our ability to handle
                      substantial daily volumes. Our well-established operations
                      and strong network enable us to efficiently manage large
                      quantities of shipments, ensuring a smooth and timely
                      transportation process.
                      <br />
                      <br />
                      Additionally, we leverage EDI (Electronic Data
                      Interchange) connections to streamline communication and
                      data exchange, enhancing efficiency and accuracy in our
                      operations. Our advanced tracking and tracing systems
                      provide real-time visibility of your shipments, allowing
                      you to stay informed about their status throughout the
                      journey.
                      <br />
                      <br />
                      At {companyname}, we understand the importance of
                      special-delivery documentation for specific shipments. Our
                      experienced team is well-equipped to handle any unique
                      documentation requirements, ensuring compliance and
                      seamless customs clearance for your goods.
                      <br />
                      <br />
                      As a company committed to high-performance and quality, we
                      uphold rigorous standards in our operations. Our focus on
                      customer service ensures that you receive personalized
                      attention and timely assistance at every step of the
                      transportation process.
                      <br />
                      <br />
                      In choosing {companyname}, you gain access to a reliable
                      and efficient partner for your trucking and multimodal
                      transportation needs. We take pride in delivering
                      exceptional service, providing peace of mind and
                      confidence in the transportation of your valuable cargo.
                      Trust in our expertise and dedication to quality as we
                      work tirelessly to ensure the success of your logistics
                      operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
