import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
  companyemail2,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row section__row">
            <div className="col-md-6 col-lg-4 col-xl-4 section__col">
              <div className="footer__single">
              <Link to="index-2.html" className="text-center hide-nav">
                        <img src="logo.png" alt="{companyname}" style={{width:'100px'}} />
                      </Link>
                <div className="footer__single-content">
                  <p>
                    {" "}
                    {companyname} stands out in the logistics industry dominated
                    by technology and performance due to its core values of
                    transparency, future-oriented thinking, trust-based business
                    partnerships, and a hardworking mentality.{" "}
                  </p>
                  {/*  <div className="social">
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f" />
                      </Link>
                      <Link to="#">
                        <i className="fa-brands fa-twitter" />
                      </Link>
                      <Link to="#">
                        <i className="fa-brands fa-square-instagram" />
                      </Link>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin-in" />
                      </Link>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 col-xl-2 section__col">
              <div className="footer__single">
                <h5>Quick Links</h5>
                <div className="footer__single-content">
                  <ul>
                  <li>
                      <Link to="/Home">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">About Us</Link>
                    </li>
                    <li>
                      <Link to="/Services">Services</Link>
                    </li>
                    <li>
                      <Link to="/Industries">Industries</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/Getquote">Getquote</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 col-xl-3 section__col">
              <div className="footer__single">
                <h5>Services</h5>
                <div className="footer__single-content">
                  <ul>
                  <li>
                            <Link  to="/Ocean">Ocean Freight</Link>
                          </li>
                          <li>
                            <Link  to="/Air">Air Freight</Link>
                          </li>
                          <li>
                            <Link  to="/Road">Road Freight</Link>
                          </li>
                          <li>
                            <Link  to="/Customs">Customs Clearance</Link>
                          </li>
                          <li>
                            <Link  to="/Warehousing">Warehousing</Link>
                          </li>
                          <li>
                            <Link  to="/Dangerous">Dangerous Cargo</Link>
                          </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 section__col">
              <div className="footer__single">
                <h5>Contact Us</h5>
                <div className="footer__single-content">
                  <div className="footer__single-content__group">
                    <p>{companynumber}</p>
                  </div>
                  <div className="footer__single-content__group">
                    <p>
                     {companyemail}
                    </p>
                    <p>
                     {companyemail2}
                    </p>
                    
                   
                  </div>
                  <div className="footer__single-content__group">
                    <p>{companyaddress}</p>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <div className="copyright">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <p>
                      Copyright © <span id="copyYear" /> {companyname}. All Rights
                      Reserved{" "}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      
                      <li>
                        <Link to="/Terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/Privacy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ==== / footer end ==== */}
      {/* scroll to top */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <Outlet />
    </>
  );
};

export default Footer;
