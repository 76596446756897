import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>High Tech</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      High Tech
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4">High Tech</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/hightech.jpg"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      The world we live in today is shaped by high-technology
                      manufacturing, giving rise to cutting-edge equipment and
                      products in industries like aerospace, healthcare, and
                      electronics. These innovations have become an integral
                      part of our daily lives, elevating our experiences and
                      propelling our generation towards further progress.
                      <br />
                      <br />
                      At {companyname}, we understand the significance of
                      high-tech solutions and developments in creating
                      innovative goods. With each passing second, breakthroughs
                      in technology breathe life into groundbreaking products
                      that enhance our lives and drive us towards a brighter
                      future.
                      <br />
                      <br />
                      To ensure the seamless transportation of these specialized
                      cargoes, we provide multi-level security control,
                      guaranteeing the safe and secure handling of your valuable
                      high-tech shipments. Our commitment to safety and security
                      reflects our dedication to protecting your cargo
                      throughout the transportation process.
                      <br />
                      <br />
                      Our Control Tower operates 24/7, monitoring the movement
                      of every special cargo with precision and efficiency. With
                      real-time tracking and proactive management, we provide
                      you with full visibility and control over your shipments,
                      allowing you to stay informed every step of the way.
                      <br />
                      <br />
                      At {companyname}, we recognize that each type of cargo
                      requires unique handling and expertise. That's why we
                      offer dedicated services tailored to meet the specific
                      requirements of various types of cargo, ensuring that your
                      high-tech products receive the care and attention they
                      deserve.
                      <br />
                      <br />
                      As your trusted partner in logistics, we take pride in
                      providing reliable and specialized solutions for the
                      transportation of high-tech equipment and products. Our
                      team of experts is committed to ensuring the timely and
                      secure delivery of your valuable cargo, contributing to
                      the continuous progress and advancements in the industries
                      that shape our world. Let us be your logistics ally as you
                      propel your high-tech innovations into the future, making
                      a lasting impact on the lives of people worldwide.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
