import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>E-Commerce</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      E-Commerce
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4">E-Commerce</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/ecommerce.jpg"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      The e-commerce sector is rapidly growing, fueled by the
                      increasing demand for cross-border purchases and
                      customers' expectations of receiving high-quality,
                      efficient, and convenient service with just a single
                      click. At {companyname}, we are dedicated to enhancing
                      your customer experience and continuously improving our
                      logistics solutions to ensure the fast, seamless, and
                      effective delivery of your internet purchases.
                      <br />
                      <br />
                      Our global network coverage allows us to access major
                      e-commerce cargo gateways worldwide, enabling us to
                      efficiently connect your shipments to their intended
                      destinations. With a focus on speed and efficiency, we
                      strive to deliver within a 72-hour timeframe, encompassing
                      all necessary ground handling procedures to expedite your
                      parcels' journey.
                      <br />
                      <br />
                      Transporting parcel and mail packages is one of our
                      specialties, and we have optimized our processes to ensure
                      their secure and timely transportation. As an e-commerce
                      logistics provider, we recognize the importance of prompt
                      and reliable deliveries, and our services are designed to
                      meet these unique demands.
                      <br />
                      <br />
                      With up to 8 flights per day from major e-commerce
                      contributing hubs such as Hong Kong, Shanghai, Beijing,
                      and Zhengzhou, we offer the flexibility and frequency
                      needed to keep up with the fast-paced world of online
                      retail. Our extensive flight options ensure that your
                      shipments are swiftly moved to meet customer expectations
                      and time-sensitive requirements.
                      <br />
                      <br />
                      For low-density cargo, we have developed special packaging
                      solutions utilizing stabilizer racks to maximize volume
                      and provide enhanced protection during transportation. Our
                      innovative approach to packaging ensures that your cargo
                      remains secure and intact throughout its journey.
                      <br />
                      <br />
                      At {companyname}, we understand the critical role
                      logistics plays in the success of e-commerce businesses.
                      That's why we are committed to providing tailored,
                      efficient, and reliable logistics solutions that support
                      your e-commerce operations. With our focus on continuous
                      improvement and dedication to customer satisfaction, we
                      aim to be your trusted logistics partner in the
                      ever-evolving world of e-commerce, helping you deliver
                      exceptional service and experiences to your customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
