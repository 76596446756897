import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Air Freight</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Air Freight
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img src="assets/images/air.jpg" alt="{companyname}" />
                  </div>
                  <div className="training__details-content">
                    <h2>Air Freight</h2>
                    <p>
                      At {companyname}, we take pride in offering comprehensive
                      logistics solutions and expert handling of
                      over-dimensional or oversized cargoes and machinery. Our
                      commitment to meeting customer requirements extends to
                      providing all necessary allied activities, ensuring a
                      seamless and efficient transportation process.
                      <br />
                      <br />
                      When it comes to air freight shipments, we adhere to Cargo
                      2000 procedures, which means that your shipments are
                      meticulously planned, controlled, and monitored from
                      door-to-door within our network. This approach guarantees
                      that your cargo is in safe hands and arrives at its
                      destination on time.
                      <br />
                      <br />
                      As your air freight partner, {companyname} offers a range
                      of advantages that set us apart from the competition. We
                      specialize in both export and import freight forwarding,
                      providing door-to-door and airport-to-airport services to
                      cater to diverse shipping needs.
                      <br />
                      <br />
                      Our expertise in handling dangerous goods ensures that
                      even sensitive or hazardous items are transported with the
                      utmost care and compliance. Whether it's exhibition cargo,
                      project cargo movement, or break bulk operations, our
                      experienced team is equipped to handle a wide variety of
                      shipments with precision and efficiency.
                      <br />
                      <br />
                      Additionally, we offer full and partial charters, giving
                      you the flexibility to choose the most suitable option for
                      your cargo. With {companyname} as your air freight
                      partner, you can expect reliable and tailored solutions
                      that guarantee a smooth and successful logistics
                      experience. Trust in our dedication to quality service and
                      our commitment to meeting your specific requirements,
                      making us the ideal choice for all your air freight needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
