import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      {/* ==== banner section start ==== */}
      <section className=" w3-banner jarallax">
        {" "}
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "1" }}
        >
          <source src="assets/images/video.mp4" type="video/mp4" />
        </video>
        <div className="banner--secondary">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-7">
                <div
                  className="banner__content wow fadeInUp"
                  data-wow-duration="0.4s"
                  style={{position:'relative', zIndex:"1"}}
                >
                  <h5 className="banner__content-sub-title" >
                    We are {companyname}
                  </h5>
                  <h1 className="banner__content-title" style={{color:"white"}}>
                    Efficiency in Motion, Worldwide Solutions.
                  </h1>
                  <p className="primary-text banner__content-text" style={{color:"white"}}>
                    Harnessing efficiency in every step of the process, our
                    worldwide solutions seamlessly connect businesses across the
                    globe, ensuring smooth and timely logistics operations.
                  </p>
                  <div className="banner__content-cta">
                    <Link to="/Contact" className="cmn-button">
                      Contact Us
                    </Link>
                    <Link
                      to="/About"
                      className="cmn-button cmn-button--secondary"
                    >
                      About Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section about--secondary wow fadeInUp"
        data-wow-duration="0.4s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-xxl-5 d-none d-lg-block">
              <div className="about--secondary__thumb dir-rtl ">
                <img src="assets/images/about.jpg" alt="img" />

                <div className="about--secondary__modal">
                  <img src="assets/images/about.jpg" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xxl-6 offset-xxl-1">
              <div className="section__content">
                <h5 className="section__content-sub-title">About us</h5>
                <h3 className="">Welcome to {companyname}</h3>
                <p className="section__content-text">
                  {companyname} stands out in the logistics industry dominated
                  by technology and performance due to its core values of
                  transparency, future-oriented thinking, trust-based business
                  partnerships, and a hardworking mentality. Our dedicated team
                  of air cargo professionals is driven by the commitment to
                  fulfill airlines' promises to customers and consistently
                  deliver value to their business interests by combining local
                  market knowledge with a global business understanding.
                  <br />
                  <br />
                  As people working with other people, we strive to create
                  confidence and trust in {companyname}'s ability to maintain
                  the highest quality of service, which is why we take pride in
                  our job. The support of our customers has earned us
                  recognition with leading industry awards, a testament to the
                  excellence of our service performance.
                </p>

                <div className="section__content-cta">
                  <Link to="/About" className="cmn-button">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section event wow fadeInUp" data-wow-duration="0.4s">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section__header--secondary">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="section__header--secondary__content">
                      <h5>Services</h5>
                      <h2>Services We Provide..</h2>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="section__header--secondary__cta">
                      <Link to="/Services" className="cmn-button">
                        See All Services
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center section__row">
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Ocean">
                    <img
                      src="assets/images/ocean.webp"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Ocean Freight</h5>
                  <p className="secondary-text">
                    {" "}
                    At {companyname}, we take pride in providing hassle-free
                    ocean freight forwarding solutions that streamline the
                    shipping process for our clients. With our fast online
                    quotes and user-friendly tools, you can easily access...
                  </p>

                  <Link to="/Ocean" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Air">
                    <img
                      src="assets/images/air.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Air Freight</h5>
                  <p className="secondary-text">
                    {" "}
                    At {companyname}, we take pride in offering comprehensive
                    logistics solutions and expert handling of over-dimensional
                    or oversized cargoes and machinery. Our commitment to
                    meeting customer requirements extends to..
                  </p>

                  <Link to="/Air" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Road">
                    <img
                      src="assets/images/road.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Road Freight</h5>
                  <p className="secondary-text">
                    {" "}
                    {companyname} stands out as a reliable choice for trucking
                    and multimodal transportation due to our extensive network
                    of associates, truckers, vendors, and operators. This vast
                    network allows us to offer tailor-made transport solutions..
                  </p>

                  <Link to="/Road" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Customs">
                    <img
                      src="assets/images/customs.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5> Customs Clearance</h5>
                  <p className="secondary-text">
                    {" "}
                    At {companyname}, we take pride in offering warehousing
                    services that are completely tailored to your specific
                    needs. With our global presence, we can provide flexible
                    warehousing solutions no matter where you are located in..
                  </p>

                  <Link to="/Customs" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Warehousing">
                    <img
                      src="assets/images/warehousing.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Warehousing</h5>
                  <p className="secondary-text">
                    {" "}
                    Our warehousing services are designed to meet the diverse
                    requirements of businesses across various industries.
                    Whether you need short-term storage for seasonal
                    fluctuations or long-term solutions for your inventory..
                  </p>

                  <Link to="/Warehousing" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Dangerous">
                    <img
                      src="assets/images/dangerous.webp"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Dangerous Cargo</h5>
                  <p className="secondary-text">
                    {" "}
                    Dangerous goods encompass a wide range of substances,
                    materials, products, industrial and other waste that possess
                    inherent properties making them potentially hazardous during
                    transportation. When exposed to specific..
                  </p>

                  <Link to="/Dangerous" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / event section end ==== */}
      <section
        className="section facility wow fadeInUp"
        data-wow-duration="0.4s"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h5 className="section__header-sub-title">Vision & Values</h5>
                <h2 className="section__header-title">
                  Our Vision and Values Towards Growth
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-10 col-lg-6">
              <div className="facility__card">
                <div className="facility__card-icon">
                  <i class="fa-solid fa-eye"></i>
                </div>
                <div className="facility__card-content">
                  <h5>
                    <Link to="facility-details.html">Vision</Link>
                  </h5>
                  <p className="secondary-text">
                    The {companyname} aims to be a customer-oriented,
                    multi-technology, multi-specialist transport system in the
                    Indian and International markets. We are committed to
                    excellence in every aspect of our activities and follow
                    value-based policies to meet the aspirations of society,
                    customers, vendors, employees, shareholders, and the
                    transport industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-lg-6">
              <div className="facility__card">
                <div className="facility__card-icon">
                  <i class="fa-solid fa-bullseye"></i>
                </div>
                <div className="facility__card-content">
                  <h5>
                    <Link to="facility-details.html">Values</Link>
                  </h5>
                  <p className="secondary-text">
                    At our core, we firmly believe that a brand is more than
                    just a name; it is a living entity with a distinct
                    personality. As such, we ensure that our brand's behavior
                    aligns with our values and principles. Our actions and
                    decisions are governed by a set of CORE values that serve as
                    the guiding force in our business endeavors, helping us pave
                    the way for a better tomorrow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / facility section end ==== */}
      {/* ==== join club section start ==== */}

      {/* ==== team section start ==== */}
      <section className="section team wow fadeInUp" data-wow-duration="0.4s">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h5 className="section__header-sub-title">Our Industries</h5>
                <h2 className="section__header-title"></h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="team__slider--secondary">
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/healthcare.webp"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Healthcare">
                  {" "}
                  <h5>Healthcare</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/oil.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Oil">
                  {" "}
                  <h5>Oil & Gas</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/heavy.jpeg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Heavy">
                  {" "}
                  <h5>Heavy Machinary</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/fashion.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Fashion">
                  {" "}
                  <h5>Fashion & Apparel</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/high.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/High">
                  {" "}
                  <h5>High Tech</h5>
                </Link>
              </div>
            </div>
            <div className="team__slider-card">
              <div className="team__slider-card__thumb">
                <img
                  src="assets/images/E-Commerce.jpg"
                  style={{ height: "200px" }}
                  alt="Industries"
                />
              </div>
              <div className="team__slider-card__content">
                <Link to="/Ecommerce">
                  {" "}
                  <h5>E-Commerce</h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="slider-navigation">
                <button className="next-team--secondary cmn-button cmn-button--secondary">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="prev-team--secondary cmn-button cmn-button--secondary">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
