import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Dangerous Cargo</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dangerous Cargo
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img
                      src="assets/images/dangerous.webp"
                      alt="{companyname}"
                    />
                  </div>
                  <div className="training__details-content">
                    <h2>Dangerous Cargo</h2>
                    <p>
                      Dangerous goods encompass a wide range of substances,
                      materials, products, industrial and other waste that
                      possess inherent properties making them potentially
                      hazardous during transportation. When exposed to specific
                      factors, these goods can trigger explosions, fires, or
                      cause damage to technical equipment, devices, structures,
                      and other objects. The transportation of dangerous goods
                      also poses significant risks of causing material damage
                      and harm to the environment, as well as potential harm to
                      human and animal life through death, injury, or poisoning.
                      <br />
                      <br />
                      The classification of dangerous goods is essential for
                      ensuring proper handling, storage, and transportation to
                      prevent accidents and mitigate potential risks. Various
                      industries, including logistics and freight forwarding,
                      adhere to strict regulations and safety measures when
                      dealing with dangerous goods.
                      <br />
                      <br />
                      Transporting hazardous materials requires specialized
                      knowledge and expertise to ensure compliance with
                      international and national regulations, as well as safety
                      standards. Proper packaging, labeling, and documentation
                      are crucial in guaranteeing the safe transport of
                      dangerous goods.
                      <br />
                      <br />
                      At {companyname}, we understand the critical nature of
                      handling dangerous goods and have a dedicated team of
                      experts well-versed in the regulations and procedures
                      involved. Our top priority is to ensure the safe
                      transportation of hazardous materials while minimizing
                      risks to people, the environment, and property. We take
                      every precaution necessary to protect our clients, their
                      cargo, and the communities we serve, reflecting our
                      commitment to safety, security, and responsible logistics
                      practices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
