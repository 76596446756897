import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2> Heavy Machinary</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Heavy Machinary
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-12 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <h2 className="text-center p-4"> Heavy Machinary</h2>

                  <div className="training__details-thumb ">
                    <img
                      src="assets/images/heavy.jpeg"
                      alt="industry"
                      style={{ padding: "10px 50px" }}
                    />
                  </div>
                  <div className="training__details-content">
                    <p>
                      The heavy machinery industry presents unique challenges
                      due to its large and weighty products, equipment, and
                      facilities. Transporting such items requires special
                      attention to ensure their safe and secure delivery. At{" "}
                      {companyname}, we understand the complexity of handling
                      heavy machinery and offer comprehensive solutions to
                      ensure that these products reach their destination without
                      any compromise.
                      <br />
                      <br />
                      Our logistics services cater to a wide range of industrial
                      equipment, including big vehicles, heavy-machinery
                      equipment, industrial spare parts, pipes, tractors, and
                      agricultural equipment. Our expertise in handling such
                      diverse and large-scale shipments ensures that we can
                      manage your project with efficiency and precision.
                      <br />
                      <br />
                      For oversized and super heavy shipments, we offer
                      dedicated {companyname} XL services, providing you with
                      the necessary resources and expertise to handle these
                      complex project shipments. We understand that such
                      shipments often require careful planning, attention to
                      detail, and a capable fleet, and we are equipped to handle
                      these challenges with ease.
                      <br />
                      <br />
                      As part of our commitment to delivering top-quality
                      logistics solutions, we provide access to the fleet of the
                      Volga-Dnepr Group. This esteemed fleet includes unique
                      ramp aircraft such as the An-124-100 and Il-76TD-90VD,
                      designed to handle various logistics challenges. With
                      access to such specialized aircraft, we can offer you a
                      wide range of transportation options for your heavy
                      machinery and complex project shipments.
                      <br />
                      <br />
                      At {companyname}, we take pride in our expertise,
                      experience, and capabilities in organizing air transport
                      deliveries for heavy machinery and industrial equipment.
                      Our team of seasoned professionals is dedicated to
                      ensuring the safe and sound delivery of your valuable
                      cargo, providing you with peace of mind and reliable
                      solutions for your logistics needs. When it comes to
                      transporting heavy machinery, trust {companyname} to
                      handle every detail with care and precision, ensuring the
                      success of your industrial projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
