import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
  companyemail2,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [randomValue1, setRandomValue1] = useState(getRandomNumber());
  const [randomValue2, setRandomValue2] = useState(getRandomNumber());
  const [userSum, setUserSum] = useState("");
  const [isSumCorrect, setIsSumCorrect] = useState(null);

  // Function to get random numbers between 1 to 10
  function getRandomNumber() {
    return Math.floor(Math.random() * 10) + 1;
  }

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const calculatedSum = randomValue1 + randomValue2;

    axios
      .post("/checkSum", { userSum: parseInt(userSum), calculatedSum })
      .then((response) => {
        setIsSumCorrect(response.data.isCorrect);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Contact Us</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section contact wow fadeInUp"
        data-wow-duration="0.4s"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h2 className="section__header-title">Contact Us</h2>
                <p>Discover Seamless Freight Services - Get in Contact!</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center section__row">
            <div className="col-sm-6 col-lg-4 col-xl-4 section__col">
              <div className="contact__item">
                <div className="contact__item-thumb">
                  <i className="golftio-phone" />
                </div>
                <div className="contact__item-content">
                  <h5>Call Now</h5>
                  <p className="secondary-text">{companynumber}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-xl-4 section__col">
              <div className="contact__item">
                <div className="contact__item-thumb">
                  <i className="golftio-email" />
                </div>
                <div className="contact__item-content">
                  <h5>Email Address</h5>
                  <p className="secondary-text">{companyemail}</p>
                  <p className="secondary-text">{companyemail2}</p>
                  <p className="secondary-text"></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-xl-4 section__col">
              <div className="contact__item">
                <div className="contact__item-thumb">
                  <i className="golftio-pin-location" />
                </div>
                <div className="contact__item-content">
                  <h5>Location</h5>
                  <p className="secondary-text">{companyaddress}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / contact section end ==== */}
      {/* ==== contact form start ==== */}
      <section className="section contact-form">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section__header">
                <h2 className="section__header-title">Get in touch with us.</h2>
                <p>Fill up the form and our team will get back to you soon..</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="contact-form__inner">
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="form-floating col-lg-6">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        required
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                    <div className=" form-floating col-lg-6">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        required
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        required
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        required
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                    <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                   

                    <div className="col-12">
                      <div className="section__cta">
                        <button
                          type="submit"
                          name="submit"
                          className="cmn-button"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

               
                {result}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
