import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2> About Us</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      <section
        className="section about--secondary wow fadeInUp"
        data-wow-duration="0.4s"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-xxl-5 d-none d-lg-block">
              <div className="about--secondary__thumb dir-rtl">
                <img src="assets/images/about.jpg" alt="img" />

                <div className="about--secondary__modal">
                  <img src="assets/images/about.jpg" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xxl-6 offset-xxl-1">
              <div className="section__content">
                <h5 className="section__content-sub-title">About us</h5>
                <h3 className="">
                  Welcome to {companyname}
                </h3>
                <p className="section__content-text">
                  {companyname} stands out in the logistics industry dominated
                  by technology and performance due to its core values of
                  transparency, future-oriented thinking, trust-based business
                  partnerships, and a hardworking mentality. Our dedicated team
                  of air cargo professionals is driven by the commitment to
                  fulfill airlines' promises to customers and consistently
                  deliver value to their business interests by combining local
                  market knowledge with a global business understanding.
                  <br />
                  <br />
                  As people working with other people, we strive to create
                  confidence and trust in {companyname}'s ability to maintain
                  the highest quality of service, which is why we take pride in
                  our job. The support of our customers has earned us
                  recognition with leading industry awards, a testament to the
                  excellence of our service performance.
                </p>
                
                
              </div>
            </div>
          </div>
          <div className="p-4">
            <p>
            Our experienced global team ensures that each client receives personalized attention through a dedicated account manager, offering a fast and responsive point-of-contact for daily business needs. We understand that collaboration with our customers is essential, and we work together to develop tailored logistics solutions that meet their specific requirements.

With sales offices strategically located across our international network, we provide fast and proactive local support and direct feedback for every shipment. Taking full responsibility for cargo shipments from beginning to end, our additional services include capacity reservation and priority booking, chartering airplanes, onboard supervision, trucking, road feeder service, customs clearance support, and more.

At {companyname}, our focus on customer service goes beyond just meeting expectations; we aim to leave a lasting mark through personalized, reliable, and efficient logistics solutions. We believe that by working together with our customers, we create stronger and more successful partnerships, driving the success of their businesses and ours.
            </p>
          </div>
        </div>
      </section>
      {/* ==== / about section end ==== */}

      

      {/* ==== event section start ==== */}
      <section className="section event wow fadeInUp" data-wow-duration="0.4s">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section__header--secondary">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="section__header--secondary__content">
                      <h5>Services</h5>
                      <h2>Services We Provide..</h2>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="section__header--secondary__cta">
                      <Link to="/Services" className="cmn-button">
                        See All Services
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center section__row">
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Ocean">
                    <img
                      src="assets/images/ocean.webp"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Ocean Freight</h5>
                  <p className="secondary-text"> At {companyname}, we take pride in providing hassle-free
                      ocean freight forwarding solutions that streamline the
                      shipping process for our clients. With our fast online
                      quotes and user-friendly tools, you can easily access...</p>

                  <Link to="/Ocean" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Air">
                    <img
                      src="assets/images/air.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Air Freight</h5>
                  <p className="secondary-text"> At {companyname}, we take pride in offering comprehensive
                      logistics solutions and expert handling of
                      over-dimensional or oversized cargoes and machinery. Our
                      commitment to meeting customer requirements extends to..</p>

                  <Link to="/Air" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Road">
                    <img
                      src="assets/images/road.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Road Freight</h5>
                  <p className="secondary-text"> {companyname} stands out as a reliable choice for trucking
                      and multimodal transportation due to our extensive network
                      of associates, truckers, vendors, and operators. This vast
                      network allows us to offer tailor-made transport solutions..</p>

                  <Link to="/Road" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Customs">
                    <img
                      src="assets/images/customs.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5> Customs Clearance</h5>
                  <p className="secondary-text"> At {companyname}, we take pride in offering warehousing
                      services that are completely tailored to your specific
                      needs. With our global presence, we can provide flexible
                      warehousing solutions no matter where you are located in..</p>

                  <Link to="/Customs" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Warehousing">
                    <img
                      src="assets/images/warehousing.jpg"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Warehousing</h5>
                  <p className="secondary-text"> Our warehousing services are designed to meet the diverse
                      requirements of businesses across various industries.
                      Whether you need short-term storage for seasonal
                      fluctuations or long-term solutions for your inventory..</p>

                  <Link to="/Warehousing" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-4 section__col">
              <div className="event__single">
                <div className="event__single-thumb">
                  <Link to="/Dangerous">
                    <img
                      src="assets/images/dangerous.webp"
                      style={{ height: "240px" }}
                      alt="{companyname}"
                    />
                  </Link>
                </div>
                <div className="event__single-content text-center">
                  <h5>Dangerous Cargo</h5>
                  <p className="secondary-text">  Dangerous goods encompass a wide range of substances,
                      materials, products, industrial and other waste that
                      possess inherent properties making them potentially
                      hazardous during transportation. When exposed to specific..</p>

                  <Link to="/Dangerous" className="cmn-button">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / event section end ==== */}
      <Footer />
    </>
  );
}
