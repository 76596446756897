import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Customs Clearance</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Customs Clearance
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img
                      src="assets/images/customs.jpg"
                      alt="{companyname}"
                    />
                  </div>
                  <div className="training__details-content">
                    <h2>Customs Clearance</h2>
                    <p>
                      Navigating customs clearance can be a challenging process,
                      especially for those new to international shipping, as
                      there is no single global standard. Regardless of the
                      means of freight, whether it's air or ocean, customs
                      clearance is a mandatory step when exporting
                      internationally. The carrier must obtain export clearance
                      before the cargo can depart from the seaport or airport of
                      origin. Similarly, upon arrival at the destination
                      country, import authorization is required before the
                      freight can be delivered to the consignee.
                      <br />
                      <br />
                      Customs clearance involves handling documentation,
                      understanding the regulations of various countries, and
                      staying up-to-date with the latest norms and legal
                      requirements. It can be perceived as a process with many
                      complexities and hassles. At {companyname}, we recognize
                      the challenges of customs clearance and offer end-to-end
                      logistics solutions to ensure hassle-free delivery of your
                      cargo to any part of the world.
                      <br />
                      <br />
                      Our team of experts is well-versed in navigating the
                      intricacies of customs procedures. We handle all the
                      necessary paperwork, ensuring compliance with regulations
                      and minimizing delays. With our comprehensive knowledge of
                      international shipping and customs requirements, we
                      streamline the customs clearance process for you, allowing
                      you to focus on other aspects of your business.
                      <br />
                      <br />
                      Whether you are exporting or importing, our customized
                      logistics solutions are designed to meet your specific
                      needs. We take care of every detail, from documentation to
                      compliance, providing you with a smooth and efficient
                      customs clearance experience. Trust in {companyname} to
                      handle your cargo with expertise and precision, ensuring
                      that it reaches its destination seamlessly, regardless of
                      the complexities involved in customs clearance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
