import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function page() {
  return (
    <>
      <Header />

      <section className="banner--inner" style={{}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner--inner__content">
                <h2>Warehousing</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner--inner__breadcrumb d-flex justify-content-start justify-content-md-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Warehousing
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / banner end ==== */}
      {/* ==== details start ==== */}
      <section className="section details">
        <div className="container">
          <div className="row section__row">
            <div className="col-12 col-xl-4 section__col">
              <div className="sidebar wow fadeInUp" data-wow-duration="0.4s">
                <div className="sidebar__single">
                  <h5>Services</h5>
                  <div className="sidebar__tab">
                    <ul>
                      <li>
                        <Link className="training-tab-btn" to="/Ocean">
                          <i className="fa-solid fa-angle-right" />
                          Ocean Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Air">
                          <i className="fa-solid fa-angle-right" />
                          Air Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Road">
                          <i className="fa-solid fa-angle-right" />
                          Road Freight
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Customs">
                          <i className="fa-solid fa-angle-right" />
                          Customs Clearance
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Warehousing">
                          <i className="fa-solid fa-angle-right" />
                          Warehousing
                        </Link>
                      </li>
                      <li>
                        <Link className="training-tab-btn" to="/Dangerous">
                          <i className="fa-solid fa-angle-right" />
                          Dangerous Cargo
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8 section__col">
              <div className="training__details">
                <div className="training__details-inner">
                  <div className="training__details-thumb">
                    <img
                      src="assets/images/warehousing.jpg"
                      alt="{companyname}"
                    />
                  </div>
                  <div className="training__details-content">
                    <h2>Warehousing</h2>
                    <p>
                      At {companyname}, we take pride in offering warehousing
                      services that are completely tailored to your specific
                      needs. With our global presence, we can provide flexible
                      warehousing solutions no matter where you are located in
                      the world.
                      <br />
                      <br />
                      Our warehousing services are designed to meet the diverse
                      requirements of businesses across various industries.
                      Whether you need short-term storage for seasonal
                      fluctuations or long-term solutions for your inventory
                      management, we have the expertise and resources to
                      accommodate your needs.
                      <br />
                      <br />
                      With our extensive network of warehouses strategically
                      positioned around the globe, we can efficiently store and
                      handle your goods at locations that best serve your supply
                      chain. Our warehouses are equipped with state-of-the-art
                      facilities and advanced warehouse management systems,
                      ensuring that your inventory is managed with precision and
                      accuracy.
                      <br />
                      <br />
                      We understand that every business is unique, and we work
                      closely with our clients to develop customized warehousing
                      solutions that align with their specific goals and
                      objectives. From order fulfillment to inventory tracking
                      and distribution, we provide end-to-end warehousing
                      services that optimize efficiency and reduce operational
                      costs.
                      <br />
                      <br />
                      Our commitment to quality and customer satisfaction is
                      reflected in our warehousing services, where we prioritize
                      safety, security, and reliability. When you choose{" "}
                      {companyname} for your warehousing needs, you gain access
                      to a global network of facilities and a team of dedicated
                      professionals ready to support your business growth and
                      success. Trust in our expertise and experience to provide
                      seamless and flexible warehousing solutions that enhance
                      the efficiency of your supply chain, no matter where you
                      are in the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==== / details end ==== */}
      <Footer />
    </>
  );
}
