import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Careers() {
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
  const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  };
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  };
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

     {/* Header Start */}
     <div className="container-fluid header bg-white p-0">
        <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
          <div className="col-md-6 p-5 mt-lg-5">
            <h1 className="display-5 animated fadeIn mb-4">Careers</h1>
            <nav aria-label="breadcrumb animated fadeIn">
              <ol className="breadcrumb text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home"></Link>
                </li>

                <li
                  className="breadcrumb-item text-body active"
                  aria-current="page"
                >
                  Careers
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 animated fadeIn">
            <img className="img-fluid" src="img/header.jpg" alt="" />
          </div>
        </div>
      </div>
      {/* Header End */}
      {/* Search Start */}
      <div
        className="container-fluid bg-primary mb-5 wow fadeIn"
        data-wow-delay="0.1s"
        style={{ padding: "35px" }}
      >
        <div className="container">
          <div className="row g-2"></div>
        </div>
      </div>
      {/* Search End */}
      {/* Call to Action Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="bg-light rounded p-3">
            <div
              className="bg-white rounded p-4"
              style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
            >
              <div className="row g-5 align-items-center">
                <h1 className="mb-3 text-center">Careers </h1>
                <h4 className="mb-3 text-center"> Fill The following form properly, Get opportunity at {companyname}</h4>
               
                <form
action="/php/thankyou-careers.php"
method="post"
onSubmit={(event) => handleSubmit(event)}
>
<div className="row p-2">
  <div className="col-md-4">
    <div className="form-floating">
      <input
        onChange={(event) => handlefullnameChange(event)}
        type="text"
        style={{
          width: "100%",
          padding: "10px",
          margin: "6px 0",
        }}
        value={fullname}
        id="fullname"
        name="fullname"
        placeholder="Your Name"
      />
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-floating">
      <input
        onChange={(event) => handleemailidChange(event)}
        type="email"
        style={{
          width: "100%",
          padding: "10px",
          margin: "6px 0",
        }}
        value={emailid}
        id="emailid"
        name="emailid"
        placeholder="Your Email"
      />
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-floating">
      <input
        onChange={(event) => handlephoneChange(event)}
        type="text"
        style={{
          width: "100%",
          padding: "10px",
          margin: "6px 0",
        }}
        value={phone}
        id="handlephoneChange"
        name="phone"
        placeholder="Phone Number"
      />
    </div>
  </div>
</div>
<label>Highest Qualification</label>
<div className="col-12 p-2">
  <div className="form-floating">
    <input
      onChange={(event) => handlequalificationChange(event)}
      type="text"
      style={{
        width: "100%",
        padding: "10px",
        margin: "6px 0",
      }}
      value={qualification}
      id="qualification"
      name="qualification"
      placeholder="Qualification"
      required
    />
  </div>
</div>
<label>Position Applied for</label>
<div className="col-12 p-2">
  <div className="form-floating">
    <input
      onChange={(event) => handlepositionChange(event)}
      type="text"
      style={{
        width: "100%",
        padding: "10px",
        margin: "6px 0",
      }}
      value={position}
      id="position"
      name="position"
      placeholder="position"
      required
    />
  </div>
</div>
<label>Add cover letter</label>
<div className="col-12 p-2">
  <div className="form-floating">
    <textarea
      onChange={(event) => handlecoverletterChange(event)}
      style={{
        width: "100%",
        padding: "10px",
        margin: "6px 0",
      }}
      placeholder="Leave a message here"
      value={coverletter}
      id="coverletter"
      name="coverletter"
      required
      defaultValue={""}
    />
  </div>
</div>
<div className="text-center">
  <button
    type="submit"
    name="submitcareers"
    defaultValue="SEND"
    className="btn btn-primary py-2 px-3 me-3"
  >
    Apply
    
  </button>
</div>
<br />
</form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Call to Action End */}
      <Footer />
    </>
  );
}
