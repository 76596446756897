import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Project() {
  return (
    <>
      <Header />

         {/* Header Start */}
         <div className="container-fluid header bg-white p-0">
        <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
          <div className="col-md-6 p-5 mt-lg-5">
            <h1 className="display-5 animated fadeIn mb-4">Project Logistics</h1>
            <nav aria-label="breadcrumb animated fadeIn">
              <ol className="breadcrumb text-uppercase">
                <li className="breadcrumb-item">
                <Link to="/Home"></Link>
                </li>
                
                <li
                  className="breadcrumb-item text-body active"
                  aria-current="page"
                >
                  Project Logistics
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 animated fadeIn">
            <img className="img-fluid" src="img/header.jpg" alt="" />
          </div>
        </div>
      </div>
      {/* Header End */}
      {/* Search Start */}
      <div
        className="container-fluid bg-primary mb-5 wow fadeIn"
        data-wow-delay="0.1s"
        style={{ padding: "35px" }}
      >
        <div className="container">
          <div className="row g-2"></div>
        </div>
      </div>
      {/* Search End */}
     {/* Call to Action Start */}
     <div className="container-xxl py-5">
          <div className="container">
            <div className="bg-light rounded p-3">
              <div
                className="bg-white rounded p-4"
                style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
              >
                <div className="row g-5 align-items-center">
                <h1 className="mb-3 text-center">Project Logistics </h1>
                  <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
                    <img
                      className="img-fluid rounded w-100"
                      src="img/service5.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="mb-4">
                      
                      <p>
                      {companyname} takes pride in being your specialized
                      partner for heavy-duty transport services and project
                      logistics. With our expertise in this field, we cater to
                      individual transport requirements by road, air, or ocean,
                      as well as handle comprehensive projects that demand
                      intricate preliminary planning and a strong knowledge
                      base.
                      <br />
                      <br />
                      When it comes to heavy-duty transport services, we
                      understand the unique challenges involved in moving
                      oversized or overweight cargo. Our team of experienced
                      professionals is well-versed in the intricacies of
                      handling such shipments, ensuring the safe and efficient
                      transport of your heavy goods. Whether it's coordinating
                      specialized equipment, obtaining permits, or managing
                      route planning, we have the capabilities and know-how to
                      deliver reliable heavy-duty transport solutions.
                      <br />
                      <br />
                      Additionally, for complex projects that require meticulous
                      planning and coordination, {companyname} is your trusted
                      partner. We bring together our expertise in road, air, and
                      ocean transport to design comprehensive project logistics
                      solutions. From the initial stages of project assessment
                      and feasibility studies to on-site support and execution,
                      we ensure that every aspect is carefully managed. Our
                      solid knowledge base, combined with our network of global
                      partners, enables us to navigate through the complexities
                      of project logistics with efficiency and precision.
                      <br />
                      <br />
                      At {companyname}, we understand that every project is
                      unique, and we tailor our services to meet your specific
                      needs. With our attention to detail, proactive approach,
                      and commitment to delivering exceptional results, we
                      ensure that your heavy-duty transport services and project
                      logistics are handled seamlessly from start to finish.
                      <br />
                      <br />
                      Choose {companyname} as your specialized partner, and
                      benefit from our extensive experience, specialized
                      knowledge, and comprehensive range of services for
                      heavy-duty transport and project logistics. Let us be your
                      trusted advisor and facilitator, providing you with
                      innovative solutions and reliable support for your most
                      challenging transportation projects.
                      </p>
                    </div>
                    
                    
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Call to Action End */}
      <Footer />
    </>
  );
}
