import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Ocean from "./pages/Ocean";
import Warehousing from "./pages/Warehousing";
import Air from "./pages/Air";
import Road from "./pages/Road";
import Project from "./pages/Project";
import Customs from "./pages/Customs";

import Careers from "./pages/Careers";
import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Dangerous from "./pages/Dangerous";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Healthcare from "./pages/Healthcare";
import Oil from "./pages/Oil";
import Heavy from "./pages/Heavy";
import Fashion from "./pages/Fashion";
import Hightech from "./pages/Hightech";
import Ecommerce from "./pages/Ecommerce";
import Vision from "./pages/Vision";





import Signin from "./admin/Signin";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ocean" element={<Ocean/>} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Dangerous" element={<Dangerous />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Oil" element={<Oil />} />
          <Route path="/Heavy" element={<Heavy />} />
          <Route path="/Fashion" element={<Fashion />} />
          <Route path="/Hightech" element={<Hightech />} />
          <Route path="/Ecommerce" element={<Ecommerce />} />
          <Route path="/Vision" element={<Vision />} />



        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));